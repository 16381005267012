// GSAP
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CSSRulePlugin } from "gsap/CSSRulePlugin";

// Utils
import {map, preloadImages, preloadFonts} from './utils';

// Locomotive
import LocomotiveScroll from 'locomotive-scroll';

// Splitting
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

// initialize Splitting
const splitting = Splitting();

const target = document.querySelector('#target');
const results = Splitting({ target: target, by: 'words' });


// Register plugins
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);

// Let me know shiz is loaded 
$('body').addClass('loaded');


// Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll
const locoScroll = new LocomotiveScroll({
  el: document.querySelector(".smooth-scroll"),
  smooth: true,
  smartphone: {smooth: true},
  tablet: {smooth: true}
  // lerp: 1
  // class: 'locoScroll'
});
// each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
locoScroll.on("scroll", ScrollTrigger.update);

// tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
ScrollTrigger.scrollerProxy(".smooth-scroll", {
  scrollTop(value) {
    return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
  }, // we don't have to define a scrollLeft because we're only scrolling vertically.
  getBoundingClientRect() {
    return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
  },
  // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
  pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed"
});


// Preload images and fonts
Promise.all([preloadImages()]).then(() => {
  // remove loader (loading class)
  document.body.classList.remove('loading');
  // update locomotive scroll
  locoScroll.update();
  // keep track of the previous and current scroll values
  let scroll = {cache: 0, current: 0};

  // Locomotive Scroll event
  locoScroll.on('scroll', obj => {
      scroll.current = obj.scroll.y;
      const distance = scroll.current - scroll.cache;
      scroll.cache = scroll.current;
      // translation value will be mapped in the interval of [-50,50] for a scroll distance of [150,-150]
      const translateY = map(distance, 150, -150, -50, 50);
      // for every word from the splitting object...
      for (const [i,word] of splitting.entries()) {
          // total number of characters for this word
          const charsTotal = word.chars.length;
          // for every char from each word...
          for (const [j,char] of word.chars.entries()) {
              // we want the middle chars to have a higher translationY value so it gives the illusion the word is bending
              const factor = j < Math.ceil(charsTotal/2) ? j : Math.ceil(charsTotal/2) - Math.abs(Math.floor(charsTotal/2) - j) - 1;
              char.style.transform = `translate3d(0,${factor*translateY}px,0)`;
          }
      }
  });
});



// GSAP Animations for home

if($('body.home-page').length >0) {

// HERO SECTION ANIMATIONS
// Change text colour on scroll
gsap.to(".hero__home--copy, .hero__home--title h1.xxxl .standout,  .hero__home--title h1.xxxl .outline", {
  scrollTrigger: {
      trigger: ".hero__home",
      scroller: ".smooth-scroll",
      start: "top top",
      end: "100px",
      scrub: 1,
      toggleActions: "restart none none none",
      pin: true,
      // markers: true,
  },
  // opacity: '0.85',
  color: '#5E256E',
  textStrokeColor: '#5E256E',
  ease: "circ.out"
  // stagger: 1
});

// Sunrise
gsap.fromTo(".hero__home--title", {zIndex: 9}, 
{zIndex: 2});

gsap.fromTo(".hero__home--title--sun", {autoAlpha: 1, ease: "power1.out", scale: 15, zIndex: 9, background: "linear-gradient(180deg, #E72141 0%, #ED6E38 100%);"}, 
{autoAlpha: 1, y: 0, duration: 2, ease: "power1.out", scale: 1, zIndex: -1, background: "linear-gradient(180deg, #E72141 0%, #ED6E38 100%);"});

// Sunset
gsap.fromTo(".hero__home--title--sun", {y: 0, scale: 1,   transformOrigin: 'center center', background: "linear-gradient(180deg, #E72141 0%, #ED6E38 100%);"}, 
{
  scrollTrigger: {
      trigger: ".hero__home",
      scroller: ".smooth-scroll",
      start: "top top",
      end: "100px",
      scrub: 1,
      toggleActions: "restart none none none",
      pin: true,
      snap: 10,
      // markers: true,
  },
  translateY: 800,
  scale: 11,
  transformOrigin: 'center center',
  ease: "power1.out",
  background: '#EAE4DF',
  duration: 1
});
// Image fade
gsap.fromTo(".hero__home--image_profile", {translateX: 0, autoAlpha: 1}, 
{
  scrollTrigger: {
      trigger: ".hero__home",
      scroller: ".smooth-scroll",
      start: "top top",
      end: "100px",
      scrub: 3,
      toggleActions: "restart none none none",
      pin: true,
      snap: 10,
      // markers: true,
  },
  autoAlpha: 0,
  ease: "circ.out"
});

// Moving BG
gsap.fromTo(".moving-background", {y: 0}, 
{
  scrollTrigger: {
      trigger: ".hero__home",
      scroller: ".smooth-scroll",
      start: "top top",
      end: "100px",
      scrub: 1,
      toggleActions: "restart none none none",
      pin: true,
      snap: 10
      // markers: true,
  },
  translateY: 200,
  scale: 1.5,
  ease: "circ.out",
  transformOrigin: 'center center',
  duration: 1
});

gsap.fromTo(".moving-background", {  
  backgroundPosition: "0 100%"}, 
  {
    backgroundPosition: "100% 100%",
    duration: 28,
    repeat: -1,
    ease: "circ.out",
    ease: "none"
  });



// Play with my balls
let tlBalls = gsap.timeline({});

tlBalls.to(".my-balls .my-balls__ball", {
  repeat: -1,
  duration: 5,
  stagger: {
    ease: "power3.inOut",
    from: "random",
    amount: 3.5
  },
  // delay: 1,
  // ease: "power3.out",
  ease: "circ.out",
  translateY: -850,
  // rotationX : -20,
  // rotationY : 20,
  autoAlpha: 0
});



let tlHero = gsap.timeline({});

tlHero.from(".splitting .char", {
  y: 50,
  duration: .8,
  stagger: {
    from: "center",
    ease: "power3.inOut",
    amount: 0.5
  },
  delay: 1,
  translateY: 75,
  rotationX : -90,
  ease: "circ.out",
  rotationY : 150,
  autoAlpha: 0
})
.fromTo(".hero__home--copy *", {
  opacity: 0,
  stagger: 0.2
}, {opacity: 1}, "-=1")
.from(".hero__home--image_profile", {
  opacity: 0,
  y: 100,
  duration: 1
}, "-=2");



// Text Ticker
// gsap.to(".text-ticker h2", {
//   scrollTrigger: {
//       trigger: ".text-ticker",
//       scroller: ".smooth-scroll",
//       start: "top top",
//       end: "+=5%",
//       scrub: true,
//       toggleActions: "play pause resume reset",
//       pin: true,
//   },
//   opacity: 1,
// });


let tlLines = gsap.timeline({
  scrollTrigger: {
    trigger: "#intro-home",
    scroller: ".smooth-scroll",
    // start: "200px top",
    start: "center center",
    end: "+=75%",
    scrub: true,
    toggleActions: "play reverse restart reverse",
    pin: true,
    // markers: true,
},
});

tlLines.from("#intro-home .mega-lead", {
  y: 150,
  duration: .6,
  stagger: .25,
  ease: "circ.out",
  // delay: .2,
  translateY: 75,
  // yoyo: true,
  opacity: 0
});



$('.btn.internal-link').click(function() {

  event.preventDefault();
  setTimeout(function(url) { window.location = url; }, 500, this.href);

  gsap.to('.post-loader', {
    // translateY: 800,
    scale: 11,
    transformOrigin: 'center center',
    background: "linear-gradient(180deg, #E72141 0%, #ED6E38 100%);",
    duration: .5,
    ease: "circ.out",
    opacity: 1,
    height: '100vh',
    width: '100vw',
  });

});


// Part 2
let sections = document.querySelectorAll('.portfolio-section--large');

sections.forEach((section, index) => {


let portTimeline = gsap.timeline({
  scrollTrigger: {
    trigger: section,
    scroller: ".smooth-scroll",
    start: 'top bottom-=100',
    toggleActions: "play reverse restart reverse",
    // markers: true
  }
});

portTimeline.to(section, {
    autoAlpha: 1,
    clipPath: 'inset(0% 0% 0%)',
    // clipPath: 'circle(100%)',
    duration: 1,
    // ease: "bounce.out"
    ease: "circ.out"
  });

  ScrollTrigger.create({
    trigger: section,
    id: index+1,
    start: '200px center',
    // delay: .2,
    end: () => `+=${section.clientHeight + 30}`,
    toggleActions: "play reverse restart reverse",
    toggleClass: {targets: section, className: "is-active"},
    // markers: true
  })
});



let introSection = document.querySelector('#home-page');
let heroHomeSection = document.querySelector('#hero-home');
let portfolioSection = document.querySelector('#portfolio-section');

$('.about-link').on('click', function() {
    locoScroll.scrollTo(introSection, 500)
});

$('.home-link').on('click', function() {
    locoScroll.scrollTo(heroHomeSection)
});

$('.work-link').on('click', function() {
    locoScroll.scrollTo(portfolioSection)
});





}



// Portfolio Section

if($('body.portfolio-page').length >0) {

// loader circle
gsap.fromTo(".hero__home--title--sun", {autoAlpha: 1, scale: 15, zIndex: 9, background: "linear-gradient(180deg, #E72141 0%, #ED6E38 100%);"}, 
{autoAlpha: 0, y: 0, duration: 1, scale: .1, zIndex: -1, background: "linear-gradient(180deg, #E72141 0%, #ED6E38 100%);"});


// Add background fade on hero load
$(".hero__portfolio").addClass("fade-hero");

const portSections = document.querySelectorAll('.portfolio__image');

portSections.forEach((section, index) => {
let portTimeline = gsap.timeline({
  scrollTrigger: {
    trigger: section,
    scroller: ".smooth-scroll",
    start: 'top bottom-=100',
    toggleActions: "play reverse restart reverse",
    // markers: true
  }
});

portTimeline.to(section, {
    autoAlpha: 1,
    clipPath: 'inset(0% 0% 0%)',
  });
  
  ScrollTrigger.create({
    trigger: section,
    id: index+1,
    start: '200px center',
    // delay: .2,
    end: () => `+=${section.clientHeight + 30}`,
    toggleActions: "play reverse restart reverse",
    toggleClass: {targets: section, className: "is-active"},
    // markers: true
  })
});


}

let portfolioSection = document.querySelector('#portfolio-section');

if(self.document.location.hash == '#mywork')

setTimeout(function(){ 
{
    locoScroll.scrollTo(portfolioSection)

}}, 300);



// each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

// after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
ScrollTrigger.refresh();


